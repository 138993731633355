<template>
  <div>
    <masthead
      title="Filing Orders"
      @changed="searchChanged"
    >
      <span />
      <b-button
        :disabled="!selection.length > 0"
        class="rounded"
        variant="danger"
        @click="showCancelModal()"
      >
        Cancel Selected
      </b-button>
    </masthead>
    <b-tabs v-model="tabIndex">
      <b-tab>
        <template
          slot="title"
        >
          Pending Orders
        </template>
        <ajax-table
          ref="filingsTable"
          :busy.sync="isBusy"
          :table-definition="tableDefinition"
          select-mode="multi"
          :selectable="true"
          :selectable-function="selectableFunction"
          :no-local-sorting="true"
          :sticky-header="false"
          :responsive="false"
          empty-text="You're all caught up! Past orders can be viewed by selecting the Finalized Orders tab."
          @selection-changed="selectionEvent"
          @loaded="onLoad"
        >
          <template slot="head(estimate)">
            <div class="tooltip-header-cell">
              Estimate
              <feather-icon
                :id="`estimate-tooltip`"
                class="tooltip-icon"
                type="help-circle"
              />
              <b-tooltip
                :target="`estimate-tooltip`"
                triggers="hover"
              >
                This column represents the estimated time to completion after filing with the state. Actual timelines may vary.
              </b-tooltip>
            </div>
          </template>

          <template slot="head(status)">
            <div class="tooltip-header-cell">
              Status
              <feather-icon
                :id="`status-tooltip`"
                class="tooltip-icon"
                type="help-circle"
              />
              <b-tooltip
                :target="`status-tooltip`"
                triggers="hover"
              >
                Watch the status of your filing by following along on the tracker. Once your filing is finished, this will move to your Finalized Orders tab above.
              </b-tooltip>
            </div>
          </template>

          <template slot="cell(company)" slot-scope="data">
            <div>{{ data.item.company.name }}</div>
          </template>
          <template slot="cell(jurisdiction)" slot-scope="data">
            <div>{{ getFilingJurisdiction(data.item) }}</div>
          </template>
          <template slot="cell(estimate)" slot-scope="data">
            <div>{{ formatEstimate(data.item) }}</div>
          </template>
          <template slot="cell(type)" slot-scope="data">
            <div>
              <div>{{ data.item.product.name }}</div>
              <div v-if="data.item.sub_registration && data.item.sub_registration.kind === 'dba'">
                <small class="text-muted">
                  {{ data.item.sub_registration.name }}
                </small>
              </div>
            </div>
            <div v-if="vipFiling(data.item)" class="vip-badge-group">
              <div class="badge badge-warning vip-badge">
                VIP
              </div>
            </div>
          </template>
          <template #row-details="data">
            <filing-tracker
              v-if="isSupportedByTracker(data.item)"
              :status="data.item.status"
              :filing-name="data.item.product.filing_name"
              :estimate="formatEstimate(data.item)"
              :steps="trackerSteps()"
            />
          </template>
          <template #cell(status)="data">
            <b-button
              v-if="isSupportedByTracker(data.item)"
              class="toggle-tracker"
              variant="link"
              aria-label="toggle tracker"
              @click="data.toggleDetails"
            >
              Tracker
              <b-icon-chevron-up v-if="data.detailsShowing" />
              <b-icon-chevron-down v-else />
            </b-button>
            <div v-else>
              {{ startCase(data.item) }}
            </div>
          </template>
          <template slot="cell(actions)" slot-scope="data">
            <b-button
              :to="{
                name: 'filingStatus',
                params: { orderId: data.item.order_id, orderItemId: data.item.id },
              }"
              variant="link"
              aria-label="View button"
            >
              View
            </b-button>
          </template>
        </ajax-table>
        <cancel-filings-modal
          :bus="bus"
          @completed="filingsCancelCompleted"
        />
      </b-tab>

      <b-tab>
        <template slot="title">
          Finalized Orders
        </template>
        <ajax-table
          ref="completedFilingsTable"
          :busy.sync="isBusy"
          :table-definition="completedTableDefinition"
          select-mode="multi"
          :selectable="false"
          :no-local-sorting="true"
          :selectable-function="selectableFunction"
          empty-text="No completed orders found!"
          @selection-changed="selectionEvent"
          @loaded="onLoadCompleted"
        >
          <template slot="head(status)">
            <div>
              Status
            </div>
          </template>

          <template slot="cell(company)" slot-scope="data">
            <div>{{ data.item.company.name }}</div>
          </template>
          <template slot="cell(jurisdiction)" slot-scope="data">
            <div>{{ getFilingJurisdiction(data.item) }}</div>
          </template>
          <template slot="cell(type)" slot-scope="data">
            <div>
              <div>{{ data.item.product.name }}</div>
              <div v-if="data.item.sub_registration && data.item.sub_registration.kind === 'dba'">
                <small class="text-muted">
                  {{ data.item.sub_registration.name }}
                </small>
              </div>
            </div>
            <div v-if="vipFiling(data.item)" class="vip-badge-group">
              <div class="badge badge-warning vip-badge">
                VIP
              </div>
            </div>
          </template>
          <template slot="cell(status)" slot-scope="data">
            <div>
              {{ startCase(data.item) }}
            </div>
          </template>
          <template slot="cell(status_date)" slot-scope="data">
            <div>{{ formatStatusDate(data.item) }}</div>
          </template>
          <template slot="cell(actions)" slot-scope="data">
            <b-button
              :to="{
                name: 'filingStatus',
                params: { orderId: data.item.order_id, orderItemId: data.item.id },
              }"
              variant="link"
              aria-label="View button"
            >
              View
            </b-button>
          </template>
        </ajax-table>
        <cancel-filings-modal
          :bus="bus"
          @completed="filingsCancelCompleted"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import Vue from 'vue'
import { pendingFilingsTrackerStepsFactory, PENDING_FILINGS_TRACKER_SUPPORT_MAP } from '@/components/Filing/PendingFiling/constants'
import { formatStandardDateFromIso } from '@/common/modules/formatters'
export default {
  name: 'PendingFilings',
  components: {
    Masthead:            () => import('@/components/shared/Masthead'),
    AjaxTable:           () => import('@/components/shared/AjaxTable'),
    FeatherIcon:         () => import('@/components/shared/FeatherIcon'),
    CancelFilingsModal:  () => import('@/components/CancelFilingsModal'),
    FilingTracker:       () => import('@/components/Filing/PendingFiling/FilingTracker'),
  },
  data() {
    return {
      query: null,
      showModal: false,
      selection: [],
      bus: new Vue(),
      hasVipFiling: false,
      filingMethods: null,
      filingMethodIds: null,
      isBusy: false,
      completedOrdersData: [],
      tabIndex: 0,
    }
  },
  computed: {
    ...mapGetters('account', {
      websiteId: 'websiteId',
    }),
    tableDefinition() {
      return {
        columns: [
          { key: 'company', label: 'Company', sortable: true },
          { key: 'jurisdiction', label: 'Jurisdiction', sortable: true },
          { key: 'estimate', label: 'Estimate', sortable: true },
          { key: 'type', label: 'Type', sortable: true, sortAs: 'order_items.product->>name' },
          { key: 'status', label: 'Status', sortable: false },
          // { key: 'actions', label: 'View' } TODO: reenable view button once FilingStatus page is actually implemented
        ],
        url: 'client/order_items/pending_filings',
        parameters: {
          search_name: 'search_pending_filings_page',
          query: this.query,
        },
        defaultOrderDirection: 'desc',
        defaultOrderBy: 'created_at',
      }
    },
    completedTableDefinition() {
      return {
        columns: [
          { key: 'company', label: 'Company', sortable: true },
          { key: 'jurisdiction', label: 'Jurisdiction', sortable: true },
          { key: 'type', label: 'Type', sortable: true, sortAs: 'order_items.product->>name' },
          { key: 'status', label: 'Status', sortable: true },
          { key: 'status_date', label: 'Status Date', sortable: true },
        ],
        url: 'client/order_items/finalized_filings',
        parameters: {
          search_name: 'search_pending_filings_page',
          query: this.query,
        },
        defaultOrderDirection: 'desc',
        defaultOrderBy: 'created_at',
      }
    },
    filingMethodsById() {
      return (this.filingMethods || []).reduce((accumulator, current) => {
        accumulator[current.id] = current
        return accumulator
      }, {})
    },
  },
  watch: {
    tabIndex(newIndex) {
      if (newIndex === 1) {
        this.$nextTick(() => {
          if (this.$refs.completedFilingsTable) {
            this.$refs.completedFilingsTable.reload()
          }
        })
      }
    },
  },
  methods: {
    ...mapActions('orderItems', ['cancelOrderItems']),
    trackerSteps() {
      return pendingFilingsTrackerStepsFactory()
    },
    selectionEvent(selection) {
      this.selection = selection
    },
    active(status) {
      return !['cancel-requested', 'refunded', 'completed', 'expired', 'submitted-to-state', 'in-progress', 'submitted-to-newspaper'].includes(status)
    },
    getFilingJurisdiction(item) {
      if (item.registration) {
        return item.registration.jurisdiction.state_province_region
      }
      else if (item.sub_registration) {
        return item.sub_registration.jurisdiction.state_province_region
      }
      else {
        return 'Federal'
      }
    },
    vipFiling(item) {
      if (item.subscription_id != null) {
        this.hasVipFiling = true
        return true
      }
      return false
    },
    selectableFunction(item) {
      return this.active(item.status) && !this.vipFiling(item)
    },
    async getFilingMethods(){
      const response = await this.$store.dispatch('filingMethods/fetchFilingMethods', {
        params: {
          id:  this.filingMethodIds,
        },
      })

      this.filingMethods = response.data
      this.isBusy = false
    },
    formatEstimate(item) {
      const filingMethodFromProduct = item.product.filing_method
      const filingMethodFromFilingMethods = this.filingMethodsById?.[filingMethodFromProduct.id]

      // prefer dynamic data, fallback to static data from product snapshot
      const docsIn = filingMethodFromFilingMethods?.docsIn || filingMethodFromProduct?.docs_in
      const filedIn = filingMethodFromFilingMethods?.filedIn || filingMethodFromProduct?.filed_in

      const hours = [docsIn, filedIn].reduce((sum, estimate) => {
        return sum + (estimate?.days * 24 || 0) + (estimate?.hours || 0)
      }, 0)

      const days = Math.ceil(hours / 24)

      return days ? `${days} days` : 'Unavailable'
    },
    formatStatusDate(item) {
      return item?.status_updated_at ? formatStandardDateFromIso(item.status_updated_at) : ""
    },
    searchChanged(search) {
      this.query = search.query
    },
    async showCancelModal() {
      this.bus.$emit('openCancelFilings', this.selection)
    },
    filingsCancelCompleted() {
      this.$refs.filingsTable.reload()
      if (this.$refs.completedFilingsTable) {
        this.$refs.completedFilingsTable.reload()
      }
    },
    onLoad(tableData) {
      this.isBusy = true
      this.filingMethodIds = tableData.map(a => a.product.filing_method.id)
      this.getFilingMethods()
    },
    onLoadCompleted(tableData) {
      this.isBusy = true
      this.completedOrdersData = tableData.map(a => a.product.filing_method.id)
      this.$nextTick(() => {
        this.isBusy = false // Ensure reactivity after DOM updates
      })
    },
    startCase: input => {
      const filingsWithNameChange = {
        'trademark': {
          'oa-received': 'Office Action Issued',
          'oa-woi': 'Office Action - Awaiting Client Input',
          'oa-drafting': 'Office Action - Drafting Response',
          'oa-submitted': 'Office Action - Response Submitted',
          'submitted-to-uspto': 'Submitted To USPTO',
          'published-intent-to-use': 'Mark Published',
          'published-in-use': 'Mark Published',
          'stale-2': 'Pending Client Response',
          'stale-3': 'Pending Client Response',
          'stale-4': 'Pending Client Response',
          'tm-refund-2': 'Trademark Refund',
          'tm-refund-3': 'Trademark Refund',
          'tm-refund-4': 'Trademark Refund',
          'clearance-search-woi': 'Clearance Search - Waiting on Information',
        },
        'cease and desist': {
          'new': 'Cease and Desist - New',
          'in-progress': 'Cease and Desist - In Progress',
          'processing': 'Cease & Desist - Processing',
          'completed': 'Cease & Desist - Completed',
        },
      }

      if (!Object.keys(filingsWithNameChange).includes(input.product.filing_name)) {
        return _.startCase(input.status)
      }
      return filingsWithNameChange[input.product.filing_name][input.status] || _.startCase(input.status)
    },
    isSupportedByTracker(item) {
      return PENDING_FILINGS_TRACKER_SUPPORT_MAP.has(`${item.product.filing_name}-${item.status}`)
    },
  },
}
</script>

<style lang="scss" scoped>
#pending-filings-page {
  .vip-badge-group {
    .vip-badge {
      color: white;
      font-size: 1.01rem;
      font-weight: 700 !important;
    }
  }
}

.tooltip-header-cell {
  white-space: nowrap;
}

.toggle-tracker {
  padding: 0;
}

:deep(.table.b-table) {
  tr.b-table-has-details td {
    border: none;
    border-top: 1px solid #dee2e6;
  }

  .expandable-row {
    padding: 0 !important;
    margin: 0 !important;
  }
}
</style>
